import { observable, action, decorate, computed } from 'mobx';
import { datadogRum } from "@datadog/browser-rum";
import { persist } from 'mobx-persist';
import CookiesUtils from 'utils/CookieUtils';
import Initials from 'utils/Initials';
import ValidateEmail from 'utils/ValidateEmail';
import ValidatePhone from 'utils/ValidatePhone';
import CalculateUrl from 'utils/CalculateUrl';
import ToolLink from 'utils/ToolLink';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.loginProjectName = '';
    this.assetsUrl = null;
    this.projectName = null;
    this.clientId = 0;
    this.clientEmail = '';
    this.clientPassword = '';
    this.errorModal = false;
    this.assetLogin = [];
    this.logo = [];
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.loggedUser = {};
    this.returnUrl = null;
    this.realLocation = undefined;
    this.longLoading = false;
    this.pdfToView = undefined;
    this.project_id = undefined;
    this.hostKey = undefined;
    this.projectUrl = undefined;
    this.clientData = undefined;
    this.proposalTemplate = [];
    this.policies = [];
    this.contactForm = undefined;
    this.contactPhone = undefined;
    this.contactEmail = undefined;
    this.currency = undefined;
    this.locale = undefined;
    this.city = undefined;
    this.submitFormOpen = false;
    this.loadingSubmitForm = false;
    this.vendorPrintForm = false;
    this.myhomeConfig = undefined;
    this.homeConfiguratorConfig = undefined;
    this.enabledTools = undefined;
    this.iban = [];
    this.videocallMode = false;
    this.videocallIsVendor = false;
    this.proposalConfirmedByClient = false;
    this.goBack = undefined;
    this.salesOnlineClientData = {
      firstName: '',
      lastName: '',
      birthplace: '',
      birthProvince: '',
      birthdate: '',
      gender: '',
      cf: '',
      residence: '',
      residenceProvince: '',
      address: '',
      houseNumber: '',
      email: '',
      phoneNumber: '',
    };
    this.salesOnlineSEPAData = {
      firstName: '',
      lastName: '',
      cf: '',
      residence: '',
      address: '',
      houseNumber: '',
      vat: '',
      ok: '',
      email: '',
    };
    this.pdf = undefined;
    this.finalConfirm = undefined;
    this.selectedQuote = undefined;
    this.step = 0;
    this.clientSecret = undefined;
    this.clientDocuments = [];
    this.clientDocumentsV2 = undefined;
    this.homePage = 'preventivi';
    this.delayedProposalData = undefined;
    this.myhomeProDisabled = false;
    this.checkingJwt = false;
    this.baseUrl = undefined;

    this.openSendConfigModal = null;
  }

  /* ACTIONS */

  /**
   * sets new location
   * @param {string} val
   */
  setRealLocation(val) {
    this.realLocation = val;
  }

  /**
   * closes Menu Mobile drawer
   */
  closeMenuMobile() {
    this.menuMobile = false;
  }

  /**
   * opens and closes the send configuration modal
   */

  setOpenSendConfigModal(value) {
    this.openSendConfigModal = value;
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData) {
    const newUserData = userData && { ...userData };
    if (newUserData && !newUserData.language) {
      newUserData.language = 'it';
    }
    this.loggedUser = newUserData;
    if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
      datadogRum.setUser({
        id: newUserData.id,
        name: newUserData.firstName,
        surname: newUserData.lastName,
        email: newUserData.email,
        role: newUserData.role,
      });
     }
  }

  /**
   *
   * @param {String} returnUrl the url to return
   */
  setReturnUrl(returnUrlData) {
    this.returnUrl = returnUrlData;
  }

  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateClientEmail(val) {
    if (this.clientEmail !== val) {
      this.clientEmail = val;
    }
  }

  /**
   * sets the loging in vendor password
   * @param {string} val the password
   */
  updateClientPassword(val) {
    if (this.clientPassword !== val) {
      this.clientPassword = val;
    }
  }

  /**
   * sets the selected client id
   * @param {number} id the selected client id
   */
  setClientId(id) {
    this.clientId = id;
  }

  setClientData(clientData) {
    this.clientData = clientData;
  }

  setClientDocumentsV2(clientDocuments) {
    this.clientDocumentsV2 = clientDocuments;
  }
  setPolicies(policies) {
    this.policies = policies;
  }

  /**
   *
   */
  logOutUser(callback) {
    CookiesUtils.logOut();
    this.delayedProposalData = undefined;
    this.returnUrl = null;
    this.clientSecret = undefined;
    if (callback) {
      callback();
    }
  }

  /**
   * resets all persisted data
   */
  logOut(alsoCookie) {
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.clientId = null;
    this.clientEmail = '';
    this.clientPassword = '';
    this.errorModal = false;
    this.loggedUser = {};
    this.returnUrl = null;
    this.submitFormOpen = false;
    this.loadingSubmitForm = false;
    this.vendorPrintForm = false;
    this.goBack = undefined;
    this.finalConfirm = undefined;
    this.clientSecret = undefined;
    this.clientDocuments = [];
    this.clientDocumentsV2 = undefined;
    this.homePage = 'preventivi';
    this.pdf = undefined;
    this.delayedProposalData = undefined;
    this.myhomeProDisabled = false;
    if (alsoCookie) {
      CookiesUtils.logOut();
    }
  }

  setClientSecret(clientSecret) {
    this.clientSecret = clientSecret;
  }

  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setGlobalEditing(val) {
    this.globalEditing = val;
  }

  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {object} obj an object containing all assets
   */
  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.assetLogin = obj.assetLogin;
      this.projectName = obj.name;
      this.logo = obj.logo;
      this.projectId = obj.id;
      this.loginProjectName = obj.displayName;
      this.assetsUrl = obj.assetsUrl;
      this.project_id = obj.id;
      this.hostKey = obj.hostKey;
      this.projectUrl = obj.projectUrl;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.proposalTemplate = obj.proposalTemplate;
      this.contactEmail = obj.contactEmail;
      this.contactForm = obj.contactForm;
      this.contactPhone = obj.contactPhone;
      this.currency = obj.currency;
      this.locale = obj.locale;
      this.city = obj.city;
      this.myhomeConfig = obj.myhomeConfig;
      this.homeConfiguratorConfig = obj.homeConfiguratorConfig;
      this.enabledTools = obj.enabledTools;
      this.iban = obj.iban;
      this.myhomeProDisabled = obj.myhomeProDisabled;
      this.baseUrl = obj.baseurl;
    }
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }

  /**
   * updates client to save
   * @param {object} data updated client
   */
  updateClientToSave(data) {
    this.clientToSave = data;
  }

  /**
   * updates client to add
   * @param {object} data client to add
   */
  updateClientToAdd(data) {
    this.clientToAdd = data;
  }

  /**
   * updates appartment to save
   * @param {object} data updated client
   */
  updateAppartmentToSave(data) {
    this.appartmentToSave = data;
  }

  /**
   * set appartment modal open/close
   * @param {boolean} val  appartmentmodal
   * @param {boolean} val2 adding appartments
   */
  setAppartmentModal(val, val2) {
    this.appartmentModal = val;
    this.addingAppartments = val2;
  }

  /**
   * sets snackbar open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbar(val, mes) {
    if (val !== this.snackbarOpen) {
      this.snackbarMessage = mes;
      this.snackbarOpen = val;
    }
  }

  /**
   * sets snackbar error open/closed
   * @param {boolean} err
   * @param {string} mes message to display
   */
  setSnackbarError(err, mes) {
    if (err !== this.snackbarError) {
      this.snackbarMessage = mes;
      this.snackbarError = err;
    }
  }

  /**
   * sets pdf to view in pdf viewer
   * @param {string} val
   */
  setPdfToView(val) {
    this.pdfToView = val;
  }

  setSubmitFormOpen(val) {
    this.submitFormOpen = val;
  }

  setLoadingSubmitForm(val) {
    this.loadingSubmitForm = val;
  }

  setVendorPrintForm(val) {
    this.vendorPrintForm = val;
  }

  isVendor() {
    const role = this.loggedUser && this.loggedUser.role;
    return role === 'admin' || role === 'vendor_manager' || role === 'vendor';
  }

  setVideocallMode(videocallMode) {
    this.videocallMode = videocallMode;
  }

  setVideocallIsVendor(videocallIsVendor) {
    this.videocallIsVendor = videocallIsVendor;
  }

  setProposalConfirmedByClient(proposalConfirmedByClient) {
    this.proposalConfirmedByClient = proposalConfirmedByClient;
  }

  setGoBack(goBack) {
    this.goBack = goBack;
  }

  setHomePage(page) {
    this.homePage = page;
  }

  setMyhomeProDisabled(myhomeProDisabled) {
    this.myhomeProDisabled = myhomeProDisabled;
  }

  updateSalesOnlineClientData(prop, value) {
    this.salesOnlineClientData = {
      ...this.salesOnlineClientData,
      [prop]: value,
    };
  }

  resetSalesOnlineClientData() {
    this.salesOnlineClientData = {
      firstName: '',
      lastName: '',
      birthplace: '',
      birthProvince: '',
      birthdate: '',
      gender: '',
      cf: '',
      residence: '',
      residenceProvince: '',
      address: '',
      houseNumber: '',
      email: '',
      phoneNumber: '',
    };
  }

  setSalesOnlineSEPAData(salesOnlineSEPAData) {
    this.salesOnlineSEPAData = salesOnlineSEPAData;
  }

  updateSalesOnlineSEPAData(prop, value) {
    this.salesOnlineSEPAData = {
      ...this.salesOnlineSEPAData,
      [prop]: value,
    };
  }

  initializeSEPAData() {
    this.salesOnlineSEPAData = {
      firstName: this.salesOnlineClientData.firstName,
      lastName: this.salesOnlineClientData.lastName,
      cf: this.salesOnlineClientData.cf,
      residence: this.salesOnlineClientData.residence,
      address: this.salesOnlineClientData.address,
      houseNumber: this.salesOnlineClientData.houseNumber,
      email: this.salesOnlineClientData.email,
      phoneNumber: this.salesOnlineClientData.phoneNumber,
      vat: this.salesOnlineClientData.vat || '',
      ok: '',
    };
  }

  setPdf(pdf) {
    this.pdf = pdf;
  }

  setDelayedProposalData(data) {
    this.delayedProposalData = data;
  }

  setFinalConfirm(value) {
    this.finalConfirm = value;
  }

  setSelectedQuote(selectedQuote) {
    this.selectedQuote = selectedQuote;
  }

  setStep(step) {
    this.step = step;
  }

  setCheckingJwt(checkingJwt) {
    this.checkingJwt = checkingJwt;
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }
  /* COMPUTED */

  /**
   * computes the initials of the currently logged user
   */
  get initials() {
    if (
      this.loggedUser &&
      this.loggedUser.firstName &&
      this.loggedUser.lastName
    ) {
      return Initials(this.loggedUser.firstName, this.loggedUser.lastName);
    } else {
      return '-';
    }
  }

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (
      this.loggedUser &&
      this.loggedUser.firstName &&
      this.loggedUser.lastName
    ) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else {
      return '-';
    }
  }

  /**
   * extra validation for the client
   */
  get clientToSaveIsValid() {
    return (
      this.clientToSave &&
      ValidateEmail(this.clientToSave.email) &&
      ValidatePhone(this.clientToSave.tel)
    );
  }

  /**
   * extra validation for the appartment
   */
  get appartmentToSaveIsValid() {
    return true;
    // return ValidateEmail(this.appartmentToSave.selectedBy);
  }

  /**
   * extra validation for the added client
   */
  get clientToAddIsValid() {
    return (
      this.clientToAdd &&
      ValidateEmail(this.clientToAdd.email) &&
      ValidatePhone(this.clientToAdd.tel)
    );
  }

  get salesOnlineSEPADataIsValid() {
    return (
      this.salesOnlineSEPAData.file &&
      this.salesOnlineSEPAData.file !== 'error' &&
      this.salesOnlineSEPAData.file !== 'loading'
    );
  }

  get languages() {
    if (this.myhomeConfig && Array.isArray(this.myhomeConfig.languages)) {
      return this.myhomeConfig.languages.map(lang => {
        if (lang === 'it') return 'it-IT';
        else if (lang === 'en') return 'en-GB';
        else return lang;
      });
    }
    return ['it-IT', 'en-GB'];
  }

  get logoutUrl() {
    const returnUrl = this.returnUrl;
    if (returnUrl) {
      return returnUrl.indexOf('http') === 0
        ? returnUrl
        : `${window.location.protocol}//${returnUrl}`;
    } else {
      let baseUrl = ToolLink('floorplanning', this);
      if (this.myhomeConfig && this.myhomeConfig.salesOnline) {
        baseUrl =
          window.location.protocol +
          '//' +
          CalculateUrl(
            'myhome',
            'salesonline',
            this,
            '/login?',
            'floorplanning'
          );
      }
      return baseUrl;
    }
  }

  get reCommerceLink() {
    const reCommerceUrl = this.enabledTools.find(
      tool => tool.name === 'ReCommerce'
    )?.url;
    return (
      reCommerceUrl ??
      this.projectUrl ??
      `${window.location.protocol}//${this.hostKey}`
    );
  }
}

decorate(Store, {
  clientSecret: [persist('object'), observable], // important
  delayedProposalData: [persist('object'), observable], // important
  projectName: [persist, observable],
  project_id: [persist, observable],
  hostKey: [persist, observable],
  projectUrl: [persist, observable],
  loginProjectName: [persist, observable],
  returnUrl: [persist, observable],
  logo: [persist('list'), observable],

  loggedUser: observable,
  assetLogin: observable,
  pageTitles: observable,
  proposalTemplate: observable,
  policies: observable,

  snackbarOpen: observable,
  snackbarMessage: observable,
  snackbarError: observable,
  longLoading: observable,
  clientToAdd: observable,
  appartmentModal: observable,
  addingAppartments: observable,
  clientEmail: observable,
  clientPassword: observable,
  clientToSave: observable,
  appartmentToSave: observable,
  avatarMenu: observable,
  menuMobile: observable,
  errorModal: observable,
  realLocation: observable,
  globalEditing: observable,
  clientData: observable,
  contactForm: observable,
  contactEmail: observable,
  contactPhone: observable,
  currency: observable,
  locale: observable,
  city: observable,
  submitFormOpen: observable,
  loadingSubmitForm: observable,
  vendorPrintForm: observable,
  myhomeConfig: observable,
  homeConfiguratorConfig: observable,
  enabledTools: observable,
  iban: observable,
  videocallMode: observable,
  videocallIsVendor: observable,
  proposalConfirmedByClient: observable,
  goBack: observable,
  salesOnlineClientData: observable,
  salesOnlineSEPAData: observable,
  pdf: observable,
  finalConfirm: observable,
  selectedQuote: observable,
  step: observable,
  clientDocuments: observable,
  clientDocumentsV2: observable,
  homePage: observable,
  myhomeProDisabled: observable,
  checkingJwt: observable,
  baseUrl: observable,
  openSendConfigModal: observable,

  setGlobalEditing: action.bound,
  setLongLoading: action.bound,
  setRealLocation: action.bound,
  setLoggedUser: action.bound,
  setReturnUrl: action.bound,
  updateClientEmail: action.bound,
  updateClientPassword: action.bound,
  setClientId: action.bound,
  setClientData: action.bound,
  logOut: action.bound,
  setAssetsByObject: action.bound,
  setErrorModal: action.bound,
  setSubmitFormOpen: action.bound,
  setLoadingSubmitForm: action.bound,
  setVendorPrintForm: action.bound,
  setPolicies: action.bound,
  isVendor: action.bound,
  updateSalesOnlineClientData: action.bound,
  resetSalesOnlineClientData: action.bound,
  updateSalesOnlineSEPAData: action.bound,
  setSalesOnlineSEPAData: action.bound,
  initializeSEPAData: action.bound,
  setPdf: action.bound,
  setFinalConfirm: action.bound,
  setSelectedQuote: action.bound,
  setStep: action.bound,
  setClientSecret: action.bound,
  setClientDocumentsV2: action.bound,
  setHomePage: action.bound,
  setMyhomeProDisabled: action.bound,
  setDelayedProposalData: action.bound,
  setCheckingJwt: action.bound,
  logOutUser: action.bound,
  setBaseUrl: action.bound,
  setOpenSendConfigModal: action.bound,

  reCommerceLink: computed,
  loggedUserFullname: computed,
  salesOnlineSEPADataIsValid: computed,
  languages: computed,
  logoutUrl: computed,
});

export default Store;
