import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from 'pages/Login';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import RouteConsts from './routes/Routes.jsx';
import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';

import Client from 'client/Client';
import DetectUrl from 'utils/DetectUrl';
import JwtChecker from 'utils/JwtChecker.jsx';
import { useStore } from 'store/storeUtils';
import Loader from 'react-loader-spinner';
import ClientComponent from 'videochat/ClientComponent.jsx';
import { observer, PropTypes } from 'mobx-react';
import { initI18n } from '@tecma/i18n';
import translation from './i18n/translations/en/translation.json';
import { loadResources } from '@tecma/i18n';
import { setBaseUrl } from '@tecma/bucket-lib';
import inizializeAnalytics from "utils/analytics.js"
if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // site refers to the Datadog site parameter of your organization
    // see Getting Started with Datadog Sites 
    site: 'datadoghq.eu',
    service: 'myhome-sell',
    env: process.env.REACT_APP_NAMESPACE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});
}
export const i18n = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: 'myhome',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
});
function loadHTMLElement(filename, filetype) {
  let fileref;
  if (filetype === 'js') {
    fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filename);
  } else if (filetype === 'css') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', filename);
  } else if (filetype.includes('icon')) {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', filetype);
    fileref.setAttribute('href', filename);
  } else if (filetype === 'manifest') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'manifest');
    fileref.setAttribute('href', filename);
  }
  if (typeof fileref !== 'undefined') {
    document.getElementsByTagName('head')[0].appendChild(fileref);
  }
}

const loadCss = projectDisplayName => {
  loadHTMLElement(
    `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
      projectDisplayName
    )}/myhome/myhome.css?time=${new Date(
      new Date().setHours(0, 0, 0, 0)
    ).getTime()}`,
    'css'
  );
};

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs)

const App = observer(() => {
  //const bypassLogin;

  const store = useStore();

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );
  const [i18nLoading, seti18nLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  if (
    prjInfo &&
    !prjInfo.loading &&
    prjInfo.called &&
    prjInfo.data &&
    prjInfo.data.getProjectInfoByHost &&
    loading
  ) {
    const project = { ...prjInfo.data.getProjectInfoByHost };
    store.setAssetsByObject(project);
    // Imposto il base url per la libreria bucket
    setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl || '');

    loadCss(project.displayName);
    document.title = store.loginProjectName + ' | My Home';
    loadHTMLElement(
      `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
        store.loginProjectName
      )}/global/img/favicon.ico`,
      'icon'
    );
    loadHTMLElement(
      `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
        store.loginProjectName
      )}/global/img/logo192.png`,
      'apple-touch-icon'
    );
    setLoading(false);
    loadResources({
      id: project.id,
      displayName: project.displayName,
      languages: store.languages,
    }).then(() => {
      seti18nLoading(false);
    });
  }

  if (loading || i18nLoading) {
    return (
      <div className="white-loader">
        <Loader type="RevolvingDot" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <div className="white-loader">
        <Loader type="RevolvingDot" color="#00BFFF" height={100} width={100} />
      </div>
      <LayoutRoot baseUrl={store.baseUrl} />
      {/* <MyHomeCountdown threshold={process.env.REACT_APP_TIMEOUT_TOKEN} /> */}
    </>
  );
});

const LayoutRoot = ({ baseUrl }) => {
  if (!baseUrl) {
    return <></>;
  }
  return (
    <Router basename={baseUrl}>
      <JwtChecker />
      <ClientComponent />
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
        {RouteConsts.filter(route => route.skipLogin)
          .sort((a, b) => a.order - b.order)
          .map(el => (
            <PublicRoute key={el.order} path={el.to} {...el} />
          ))}
        {RouteConsts.filter(route => !route.skipLogin)
          .sort((a, b) => a.order - b.order)
          .map(el => (
            <PrivateRoute key={el.order} path={el.to} {...el} />
          ))}
        {/* DEFAULT */}
        <PrivateRoute {...RouteConsts[0]} path={'/'} exact={false} />
      </Switch>
    </Router>
  );
};

LayoutRoot.propTypes = {
  baseUrl: PropTypes.string,
};

export default App;
